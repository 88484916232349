<template>
  <div class="bg-white pb-8 pt-14 text-neutral-800 lg:pb-16 lg:pt-[7.5rem]">
    <div v-if="data.title" class="container mb-20 text-center lg:mb-24">
      <Heading v-if="data.title" :content="data.title" :level="2" class="mb-4 font-bold lg:mb-6" />
      <div v-if="data.subtitle" class="text-base lg:text-xl">
        {{ data.subtitle }}
      </div>
    </div>
    <ul>
      <li
        v-for="(item, index) in data.links"
        :key="index"
        class="min-h-[88px] transition-all duration-100 hover:font-black hover:italic hover:text-white lg:min-h-[183px]"
        :style="{ backgroundColor: item.color.hex }"
        @mouseover="activeIndex = index"
        @mouseleave="activeIndex = undefined"
      >
        <div
          class="split-title-item container block min-h-[88px] w-full px-8 text-3xl uppercase transition-colors duration-300 lg:min-h-[183px] lg:px-16 lg:text-6xl"
          :style="{
            backgroundColor: activeIndex === index ? item.color.hex : 'white',
            '--background-color': item.color.hex
          }"
          role="button"
          @click="handleRedirect(item)"
        >
          <div class="background-animate" />
          <div
            class="relative z-2 flex items-center justify-between border-b border-neutral-300 py-6 hover:border-transparent lg:py-12"
            :class="{ '!border-transparent': activeIndex && activeIndex - 1 === index }"
          >
            {{ item.title }}
            <div class="not-italic">
              <Button
                class="!hidden lg:!inline-flex"
                :class="activeIndex === index ? 'opacity-1' : 'opacity-0'"
                :label="$t('more')"
                :size="'large'"
                :to="localePathByType(item.link?.__typename as string, item.link?.slug as string)"
                theme="dark"
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const { localePathByType } = useLocalePathByType()
const router = useRouter()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const activeIndex = ref<number | undefined>()

const { menuHide } = useMenu()

function handleRedirect(link: any) {
  router.push(
    localePathByType(link.reference?.__typename as string, link.reference?.slug as string)
  )
  menuHide()
}
</script>

<style lang="postcss">
.split-title-item {
  --background-color: white;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    top: 0;
    display: block;
    position: absolute;
    height: 100%;
    width: 12px;
    background: var(--background-color);

    @screen lg {
      width: 24px;
    }
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  .background-animate {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--background-color); /* Initial color */
    transition: width 0.3s ease-out; /* Adjust the duration as needed */
  }

  &:hover {
    .background-animate {
      width: 100%;
    }
  }
}
</style>
