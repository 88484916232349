import { breakpointsTailwind } from '@vueuse/core'
import { useAppStore } from '~/stores/AppStore'

export const useMenuVisible = () => useState('menu', () => false)

export default function () {
  const { bodyLock, bodyUnlock } = useBodyLock()
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isScreenLg = breakpoints.greater('lg')

  // Main Menu
  const isVisible = useState('menu')
  const store = useAppStore()

  function menuHide() {
    isVisible.value = false
    bodyUnlock()
    store.setLockSmoothScroll(false)
  }

  function menuShow() {
    isVisible.value = true
    bodyLock()
    store.setLockSmoothScroll(true)
  }

  function menuToggle() {
    if (!isVisible.value) {
      menuShow()
    } else {
      menuHide()
    }
  }

  const isSubmenuVisible = ref(false)

  function submenuShow() {
    if (isScreenLg.value) {
      isSubmenuVisible.value = true
    }
  }

  function submenuHide() {
    if (isScreenLg.value) {
      isSubmenuVisible.value = false
    }
  }

  function submenuToggle() {
    isSubmenuVisible.value = !isSubmenuVisible.value
  }

  return {
    isSubmenuVisible,
    submenuShow,
    submenuToggle,
    submenuHide,
    menuHide,
    menuShow,
    menuToggle
  }
}
